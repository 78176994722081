import { useQuery } from 'react-query';
import { getAll } from 'src/utilities/getAll';
import { GetAccountInfoKey as accountQueryKey } from './account';
import { APIError, getNotifications, Notification } from '@bitnimbus/api-v4';

export const queryKey = [accountQueryKey, 'notifications'];

export const useNotificationsQuery = () =>
  useQuery<Notification[], APIError[]>({
    queryKey,
    queryFn: getAllNotifications,
  });

export const getAllNotifications = () =>
  getAll<Notification>(getNotifications)()
    .then((data) => data.data)
    .catch(() => []);
