import {
  Account,
  AccountUsage,
  deleteAccount,
  getAccountInfo,
  updateAccountInfo,
  getAccountUsage,
} from '@bitnimbus/api-v4/lib/account';
import { APIError, Response } from '@bitnimbus/api-v4/lib/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useProfile } from 'src/queries/profile';
import { queryPresets } from './base';
import { Feedback } from '@bitnimbus/api-v4';

const baseKey = ['account'];
export const GetAccountInfoKey = [...baseKey];
export const GetAccountUsageKey = [...baseKey, 'usage'];

export const useAccount = () => {
  const { data: profile } = useProfile();

  return useQuery<Account, APIError[]>(GetAccountInfoKey, getAccountInfo, {
    ...queryPresets.oneTimeFetch,
    ...queryPresets.noRetry,
  });
};

export const useMutateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<Account, APIError[], Partial<Account>>(updateAccountInfo, {
    onSuccess(account) {
      queryClient.setQueryData(GetAccountInfoKey, account);
    },
  });
};

export const useDeleteAccountMutation = () => {
  return useMutation<{}, APIError[], Feedback>(deleteAccount);
};

export const useAccountUsage = () => {
  return useQuery<Response<AccountUsage>, APIError[]>(
    GetAccountUsageKey,
    getAccountUsage,
    {
      ...queryPresets.oneTimeFetch,
    }
  );
};
