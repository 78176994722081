import { QueryClient } from 'react-query';
import { useStore } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import accountManagement, {
  defaultState as defaultAccountManagementState,
  State as AccountManagementState,
} from 'src/store/accountManagement/accountManagement.reducer';
import { State as AuthState } from 'src/store/authentication';
import authentication, {
  defaultState as authenticationDefaultState,
} from 'src/store/authentication/authentication.reducer';
import events, {
  defaultState as eventsDefaultState,
  State as EventsState,
} from 'src/store/events/event.reducer';
import globalErrors, {
  defaultState as defaultGlobalErrorState,
  State as GlobalErrorState,
} from 'src/store/globalErrors';

import featureFlagsLoad, {
  defaultState as featureFlagsLoadState,
  State as FeatureFlagsLoadState,
} from './featureFlagsLoad/featureFlagsLoad.reducer';
import initialLoad, {
  defaultState as initialLoadState,
  State as InitialLoadState,
} from './initialLoad/initialLoad.reducer';
import combineEventsMiddleware from './middleware/combineEventsMiddleware';
import mockFeatureFlags, {
  defaultMockFeatureFlagState,
  MockFeatureFlagState,
} from './mockFeatureFlags';
import pendingUpload, {
  defaultState as pendingUploadState,
  State as PendingUploadState,
} from './pendingUpload';
import { initReselectDevtools } from './selectors';

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
initReselectDevtools();

/**
 * Default State
 */
const __resourcesDefaultState = {
  accountManagement: defaultAccountManagementState,
};

export interface ResourcesState {
  accountManagement: AccountManagementState;
}

export interface ApplicationState {
  __resources: ResourcesState;
  authentication: AuthState;
  events: EventsState;
  pendingUpload: PendingUploadState;
  initialLoad: InitialLoadState;
  featureFlagsLoad: FeatureFlagsLoadState;
  globalErrors: GlobalErrorState;
  mockFeatureFlags: MockFeatureFlagState;
}

export const defaultState: ApplicationState = {
  __resources: __resourcesDefaultState,
  authentication: authenticationDefaultState,
  events: eventsDefaultState,
  pendingUpload: pendingUploadState,
  initialLoad: initialLoadState,
  featureFlagsLoad: featureFlagsLoadState,
  globalErrors: defaultGlobalErrorState,
  mockFeatureFlags: defaultMockFeatureFlagState,
};

/**
 * Reducers
 */
const __resources = combineReducers({
  accountManagement,
});

const reducers = combineReducers<ApplicationState>({
  __resources,
  authentication,
  events,
  pendingUpload,
  initialLoad,
  featureFlagsLoad,
  globalErrors,
  mockFeatureFlags,
});

const enhancersFactory = (queryClient: QueryClient) =>
  compose(
    applyMiddleware(
      thunk,
      combineEventsMiddleware(
        [],
        queryClient
      )
    ),
    reduxDevTools ? reduxDevTools() : (f: any) => f
  ) as any;

// We need an instance of the query client for some event event handlers
export const storeFactory = (queryClient: QueryClient) =>
  createStore(reducers, defaultState, enhancersFactory(queryClient));

export type ApplicationStore = Store<ApplicationState>;

export const useApplicationStore = (): ApplicationStore =>
  useStore<ApplicationState>();
