import { ThunkActionCreator } from 'src/store/types';
import { getEnvLocalStorageOverrides } from 'src/utilities/storage';
import { handleLogout as _handleLogout } from './authentication.actions';
import { ApiConfig } from '@bitnimbus/api-v4';

/**
 * Revokes auth token used to make HTTP requests
 *
 * @param { string } client_id - the ID of the client app
 * @param { string } token - the auth token used to make HTTP requests
 *
 */
export const handleLogout: ThunkActionCreator<
  void,
  {
    client_id: string;
    token: string;
  }
> = ({ client_id, token }) => (dispatch) => {
  const localStorageOverrides = getEnvLocalStorageOverrides();

  const loginURL = localStorageOverrides?.loginRoot ?? ApiConfig.DashboardUrl;
  dispatch(_handleLogout());
  window.location.assign(`${loginURL}/`);
};
