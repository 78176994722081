import {
  Image,
  KubernetesCluster,
  NodeBalancer,
  Region,
  Volume,
} from '@bitnimbus/api-v4';
import { Domain } from '@bitnimbus/api-v4/lib/domains';
import { ObjectStorageBucket } from '@bitnimbus/api-v4/lib/object-storage';
import * as React from 'react';
import { compose, withStateHandlers } from 'recompose';
import entitiesErrors, {
  ErrorObject,
} from 'src/store/selectors/entitiesErrors';
import { refinedSearch } from './refinedSearch';
import {
  SearchableItem,
  SearchResults,
  SearchResultsByEntity,
} from './search.interfaces';
import { emptyResults, separateResultsByEntity } from './utils';
import { connect } from 'react-redux';
import entitiesLoading from 'src/store/selectors/entitiesLoading';
import { ApplicationState } from 'src/store';

interface HandlerProps {
  search: (
    query: string,
    buckets: ObjectStorageBucket[],
    domains: Domain[],
    volumes: Volume[],
    clusters: KubernetesCluster[],
    images: Image[],
    regions: Region[],
    searchableLinodes: SearchableItem<string | number>[],
    nodebalancers: NodeBalancer[]
  ) => SearchResults;
}
export interface SearchProps extends HandlerProps {
  combinedResults: SearchableItem[];
  entities: SearchableItem[];
  entitiesLoading: boolean;
  searchResultsByEntity: SearchResultsByEntity;
  errors: ErrorObject;
}

export const search = (
  entities: SearchableItem[],
  inputValue: string
): SearchResults => {
  if (!inputValue || inputValue === '') {
    return { searchResultsByEntity: emptyResults, combinedResults: [] };
  }

  const combinedResults = refinedSearch(inputValue, entities);

  return {
    combinedResults,
    searchResultsByEntity: separateResultsByEntity(combinedResults),
  };
};

export default () => (Component: React.ComponentType<any>) => {
  const WrappedComponent: React.FC<SearchProps> = (props) => {
    return React.createElement(Component, {
      ...props,
    });
  };

  const connected = connect((state: ApplicationState) => {
    return {
      entities: [],
      entitiesLoading: entitiesLoading(state.__resources),
      errors: entitiesErrors(state.__resources),
    };
  });

  return compose<SearchProps, {}>(
    connected,
    withStateHandlers<any, any, any>(
      { searchResultsByEntity: emptyResults },
      {
        search: (_) => (
          query: string,
          searchableLinodes: SearchableItem<string | number>[],
        ) => {
          const results = search(
            [
              ...searchableLinodes,
            ],
            query
          );
          const { searchResultsByEntity, combinedResults } = results;
          return {
            searchResultsByEntity,
            combinedResults,
          };
        },
      }
    )
  )(WrappedComponent);
};
