import { APIError } from '@bitnimbus/api-v4/lib/types';
import '@reach/menu-button/styles.css';
import '@reach/tabs/styles.css';
import 'highlight.js/styles/a11y-dark.css';
import 'highlight.js/styles/a11y-light.css';
import { useSnackbar } from 'notistack';
import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DocumentTitleSegment } from 'src/components/DocumentTitle';
import withFeatureFlagConsumer from 'src/containers/withFeatureFlagConsumer.container';
import withFeatureFlagProvider from 'src/containers/withFeatureFlagProvider.container';
import { EventWithStore, events$ } from 'src/events';
import TheApplicationIsOnFire from 'src/features/TheApplicationIsOnFire';
import GoTo from './GoTo';
import IdentifyUser from './IdentifyUser';
import MainContent from './MainContent';
import { useAuthentication } from './hooks/useAuthentication';
import useFeatureFlagsLoad from './hooks/useFeatureFlagLoad';
import { loadScript } from './hooks/useScript';
import { useMutatePreferences, usePreferences } from './queries/preferences';
import { ApplicationState } from './store';
import { getNextThemeValue } from './utilities/theme';
import { ErrorBoundary } from './components/ErrorBoundary';

// Ensure component's display name is 'App'
export const App = () => <BaseApp />;

const BaseApp = withFeatureFlagProvider(
  withFeatureFlagConsumer(() => {
    const history = useHistory();

    const { data: preferences } = usePreferences();
    const { mutateAsync: updateUserPreferences } = useMutatePreferences();

    const { featureFlagsLoading } = useFeatureFlagsLoad();
    const appIsLoading = useSelector(
      (state: ApplicationState) => state.initialLoad.appIsLoading
    );
    const { loggedInAsCustomer } = useAuthentication();

    const { enqueueSnackbar } = useSnackbar();

    const [goToOpen, setGoToOpen] = React.useState(false);

    const theme = preferences?.theme;
    const keyboardListener = React.useCallback(
      (event: KeyboardEvent) => {
        const letterForThemeShortcut = 'D';
        const letterForGoToOpen = 'K';
        const modifierKey = isOSMac ? 'ctrlKey' : 'altKey';
        if (event[modifierKey] && event.shiftKey) {
          switch (event.key) {
            case letterForThemeShortcut:
              const currentTheme = theme;
              const newTheme = getNextThemeValue(currentTheme);

              updateUserPreferences({ theme: newTheme });
              break;
            case letterForGoToOpen:
              setGoToOpen(!goToOpen);
              break;
          }
        }
      },
      [goToOpen, theme, updateUserPreferences]
    );

    React.useEffect(() => {
      if (
        import.meta.env.PROD &&
        !import.meta.env.REACT_APP_DISABLE_NEW_RELIC
      ) {
        loadScript('/new-relic.js');
      }
    }, []);

    React.useEffect(() => {
      /**
       * Send pageviews
       */
      return history.listen(({ pathname }) => {
        // Send Adobe Analytics page view events
        if ((window as any)._satellite) {
          (window as any)._satellite.track('page view', {
            url: pathname,
          });
        }
      });
    }, [history]);

    React.useEffect(() => {
      /**
       * Allow an Easter egg for toggling the theme with
       * a key combination
       */
      // eslint-disable-next-line
      document.addEventListener('keydown', keyboardListener);
      return () => {
        document.removeEventListener('keydown', keyboardListener);
      };
    }, [keyboardListener]);

    /*
     * We want to listen for migration events side-wide
     * It's unpredictable when a migration is going to happen. It could take
     * hours and it could take days. We want to notify to the user when it happens
     * and then update the Linodes in LinodesDetail and LinodesLanding
     */
    const handleMigrationEvent = React.useCallback(
      ({ event }: EventWithStore) => {
        const { entity: migratedLinode } = event;
        if (event.action === 'linode_migrate' && event.status === 'finished') {
          enqueueSnackbar(
            `Linode ${migratedLinode!.label} migrated successfully.`,
            {
              variant: 'success',
            }
          );
        }

        if (event.action === 'linode_migrate' && event.status === 'failed') {
          enqueueSnackbar(`Linode ${migratedLinode!.label} migration failed.`, {
            variant: 'error',
          });
        }
      },
      [enqueueSnackbar]
    );

    React.useEffect(() => {
      const eventHandlers: {
        filter: (event: EventWithStore) => boolean;
        handler: (event: EventWithStore) => void;
      }[] = [];

      const subscriptions = eventHandlers.map(({ filter, handler }) =>
        events$.filter(filter).subscribe(handler)
      );

      return () => {
        subscriptions.forEach((sub) => sub.unsubscribe());
      };
    }, []);

    return (
      <ErrorBoundary fallback={<TheApplicationIsOnFire />}>
        {/** Accessibility helper */}
        <a href="#main-content" className="skip-link">
          Skip to main content
        </a>
        <div hidden>
          <span id="new-window">Opens in a new window</span>
          <span id="external-site">Opens an external site</span>
          <span id="external-site-new-window">
            Opens an external site in a new window
          </span>
        </div>
        <GoTo open={goToOpen} onClose={() => setGoToOpen(false)} />
        {/** Update the LD client with the user's id as soon as we know it */}
        <IdentifyUser />
        <DocumentTitleSegment segment="Linode Manager" />
        {featureFlagsLoading ? null : (
          <MainContent
            appIsLoading={appIsLoading}
            isLoggedInAsCustomer={loggedInAsCustomer}
          />
        )}
      </ErrorBoundary>
    );
  })
);

export const hasOauthError = (...args: (Error | APIError[] | undefined)[]) => {
  return args.some((eachError) => {
    const cleanedError: string | JSX.Element = pathOr(
      '',
      [0, 'reason'],
      eachError
    );
    return typeof cleanedError !== 'string'
      ? false
      : cleanedError.toLowerCase().includes('oauth');
  });
};

export const isOSMac = navigator.userAgent.includes('Mac');
