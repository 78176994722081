import { path } from 'ramda';
import { createSelector } from 'reselect';
import { ApplicationState } from 'src/store';

type State = ApplicationState['__resources'];

export interface ErrorObject {
  hasErrors: boolean;
  linodes: boolean;
  nodebalancers: boolean;
}

export const linodesErrorSelector = (state: State) => false;
export const nodeBalsErrorSelector = (state: State) => false; //  Boolean(state.nodebalancers.error && state.nodebalancers.error.length > 0)

export default createSelector(
  linodesErrorSelector,
  nodeBalsErrorSelector,
  (linodes, nodebalancers) => ({
    linodes,
    nodebalancers,
    hasErrors: linodes || nodebalancers,
  })
);
