import { IconButtonProps, IconButton as _IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IconButton = styled(_IconButton, {
    label: "StyledIconButton",
    shouldForwardProp: (prop) => true,
})(({ theme, ...props }) => ({
    '.MuiSvgIcon-root': {
        width: '20px',
        height: '20px'
    }
}));

export type { IconButtonProps };

