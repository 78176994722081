export const latoWeb = {
  normal: '"LatoWeb", sans-serif',
  semiBold: '"LatoWebSemibold", sans-serif',
  bold: '"LatoWebBold", sans-serif',
} as const;

export const poppins = {
  normal: 'poppins',
  semiBold: 'poppins',
  bold: 'poppins',
};
