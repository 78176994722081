import * as React from 'react';
import { default as _Chip, ChipProps as _ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { isPropValid } from 'src/utilities/isPropValid';

export interface ChipProps extends _ChipProps {
  /**
   * Optional component to render instead of a span.
   */
  component?: string;
  /**
   * If true, the chip will inherit styles to allow for use in a table.
   * @default false
   */
  inTable?: boolean;
  error?: boolean;
  /**
   * The color of the outline when the variant is outlined.
   * @default 'gray'
   */
  outlineColor?: 'green' | 'gray';
  type?: 'default';
  backgroundColor?: string;
}

export const Chip = ({
  outlineColor = 'gray',
  className,
  inTable,
  ...props
}: ChipProps) => {
  return (
    <StyledChip
      inTable={inTable}
      outlineColor={outlineColor}
      className={className}
      {...props}
    />
  );
};

const StyledChip = styled(_Chip, {
  label: 'StyledChip',
  shouldForwardProp: (prop) => isPropValid(['inTable', 'outlineColor'], prop),
})<ChipProps>(({ theme, ...props }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  fontSize: '1rem',
  borderRadius: '8px',
  ...(props.inTable && {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: theme.spacing(2),
    minHeight: theme.spacing(2),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  }),
  ...(props.type === 'default' && {
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
    backgroundColor: props.backgroundColor || 'transparent',
    '.MuiChip-deleteIcon': {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  }),
  ...(props.variant === 'outlined' && {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  }),
  ...(props.error && {
    border: `1px solid ${theme.color.red}`,
    color: theme.color.red,
    '.MuiChip-deleteIcon': {
      color: theme.color.red,
      '&:hover': {
        color: theme.color.red,
      },
    },
  }),
}));
